.footer{
    background-color: #284257;
    height: 260px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer .content{
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer .companyOptions{
    display: flex;
    justify-content: space-between;
}
.footer .companyOptions img{
    width: 198px;
    height: 42px;
}
.footer .companyOptions ul{
    display: flex;
    height: 42px;
    justify-content: space-around;
    align-items: center;
    margin-left: 60px;
    overflow: hidden;
}
.footer .companyOptions ul li{
    padding:0 20px;
    font-size: 16px;
    cursor: pointer;
}
.footer .companyPresentation{
    margin-top: 60px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;;
}
.footer .companyPresentation div:first-of-type p{
    font-size: 14px;
    line-height: 14px;
    color: rgba(250, 250, 250, .6);
}
.footer .companyPresentation div:first-of-type p a{
    cursor: pointer;
    color: white;
    color: rgba(250, 250, 250, .6);
}
.footer .companyPresentation div:first-of-type p:last-of-type{
    margin-top: 12px;
}
.footer .companyPresentation div:last-of-type{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer .companyPresentation div:last-of-type img{
    width: 34px;
    height: 34px;
}
.footer .companyPresentation div:last-of-type p{
    font-size: 28px;
    margin-left: 15px;
    color:rgba(245,245,245,1);
}
.footer .rightView{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer .rightView img{
    width: 128px;
    height: 128px;
}
.footer .rightView p{
    text-align: center;
    font-size: 14px;
    line-height: 14px;
    margin-top: 10px;
}
.footer .rightView div:first-of-type{
    margin-right: 50px;
}