.SignInMain{
    width: 440px;
    background:rgba(255,255,255,1);
    box-shadow:0px 1px 10px 0px rgba(0, 0, 0, 0.17);
}
.SignInMain .SignInType,.SignInMain .otherWaysTitle{
    height: 37px;
    border-bottom:1px solid rgba(230,230,230,1);
    position: relative;
}
.SignInMain .SignInType p,.SignInMain .otherWaysTitle p{
    position: absolute;
    padding: 0 8px;
    line-height: 20px;
    background-color: #ffffff;
    top: 25px;left: 50%;
    transform: translateX(-50%);
}
.SignInMain .otherWaysTitle p{
    font-size: 14px;
    color: #999999;
    top: 27px;
}
.SignInMain .SignInType p{
    color: #1A1A1A;
    font-size: 20px;
}
.loginFrom{
    padding: 40px 40px 45px;
}
.modeTitle{
    font-size: 16px;
    color: #1a1a1a;
    line-height: 16px;
    height: 16px;
}
.modeTitle a{
    float: right;
}
.numberCodeImg,.numberCodeBut{
    width: 97px;
    height: 16px;
    border-left: 1px solid rgba(204,204,204,1);
    position: relative;
}
.numberCodeImg img,.numberCodeBut span{
    width: 74px;
    height: 24px;
    position: absolute;
    left: 50%;top: 50%;
    transform: translate(-50%,-50%);
	color: #55C6C1;
    cursor: pointer;
    font-size: 14px;
}
.controlPassword{
    font-size: 12px;
    color: #999999;
    line-height: 12px;
    margin-top: -10px;
    float: right;
}
.otherWays{
    width: 278px;
    margin: 27px auto 0;
    display: flex;
    justify-content: space-between;
}
.otherWays li{
    width:132px;
    height:42px;
    background:rgba(240,240,240,1);
    border:1px solid rgba(230,230,230,1);
    border-radius:21px;
    line-height: 42px;
    text-align: center;
}
.otherWays li a{
    color: #d6c6c6;
    font-size: 16px;
}
.numberCodeBut button{
    background: none;
    border: none;
    color: #55C6C1;
    font-size: 14px;
}
.registrationProtocol{
    margin-top: -10px;
    margin-bottom: 0;
}
.ant-form{
    margin-top: 7px;
}