.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 清除内外边距 */
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, /* structural elements 结构元素 */
dl, dt, dd, ul, ol, li, /* list elements 列表元素 */
pre, /* text formatting elements 文本格式元素 */
fieldset, lengend, button, input, textarea, /* form elements 表单元素 */
th, td { /* table elements 表格元素 */
    margin: 0;
    padding: 0;
    font-family:Microsoft YaHei !important;
}

/* 设置默认字体 */
body,
button, input, select, textarea { /* for ie */
    /*font: 12px/1 Tahoma, Helvetica, Arial, "宋体", sans-serif;*/
    font: 12px/1 Tahoma, Helvetica, Arial, "\5b8b\4f53", sans-serif; /* 用 ascii 字符表示，使得在任何编码下都无问题 */
}

h1 { font-size: 18px; /* 18px / 12px = 1.5 */ }
h2 { font-size: 16px; }
h3 { font-size: 14px; }
h4, h5, h6 { font-size: 100%; }

address, cite, dfn, em, var { font-style: normal; } /* 将斜体扶正 */
code, kbd, pre, samp, tt { font-family: "Microsoft YaHei", Courier, monospace; } /* 统一等宽字体 */
small { font-size: 12px; } /* 小于 12px 的中文很难阅读，让 small 正常化 */

/* 重置列表元素 */
ul, ol { list-style: none; }

/* 重置文本格式元素 */
a { text-decoration: none; }
a:hover { text-decoration: underline; }

abbr[title], acronym[title] { /* 注：1.ie6 不支持 abbr; 2.这里用了属性选择符，ie6 下无效果 */
border-bottom: 1px dotted;
cursor: help;
}

q:before, q:after { content: ''; }

/* 重置表单元素 */
legend { color: #000; } /* for ie6 */
fieldset, img { border: none; } /* img 搭车：让链接里的 img 无边框 */
/* 注：optgroup 无法扶正 */
button, input, select, textarea {
    font-size: 100%; /* 使得表单元素在 ie 下能继承字体大小 */
}

/* 重置表格元素 */
table {
border-collapse: collapse;
border-spacing: 0;
}

/* 重置 hr */
hr {
    border: none;
    height: 1px;
}

input:focus,button:focus{
  outline:none;
}
p,span{
  cursor: default;
}
button{
  cursor: pointer;
}
.Card{
    background-color: #ffffff;
    padding:20px 40px 40px;
    box-sizing: border-box;
    position: relative;
}
.Card:not(:first-of-type){
    margin-top: 20px;
}
.Card .title{
    line-height: 24px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}
.Card .title p{
    font-size: 24px;
    color: #1A1A1A;
    font-weight: 500;
    
}
.Card .title a{
    font-size: 14px;
    color: #999999;
    cursor: pointer;
}
.lineCourseList{

}
.lineCourseList{
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
}
.lineCourseList>li{
    width: 260px;
    height: 240px;
    margin-top: 30px;
    cursor: pointer;
    margin-left: 25px;
}
.lineCourseList>li:nth-of-type(4n+1){
    margin-left: 0;
}
.lineCourseList>li p,.lineCourseList>li span{
    cursor: pointer;
}
.lineCourseList>li:nth-of-type(-n+4){
    margin-top: 0;
}
.lineCourseList>li>.banner{
  height: 146px;
  position: relative;
  overflow: hidden;
}
.lineCourseList>li>.banner img{
    width: 100%;
    position: absolute;
    left: 50%;top: 50%;
    transform: translate(-50%,-50%);
    transition: 0.5s;
}
.lineCourseList>li>.banner:hover img{
  transform: translate(-50%,-50%) scale(1.1,1.1);
}
.lineCourseList>li .detail{
    height: 94px;
    box-sizing: border-box;
    background:rgba(255,255,255,1);
    border:1px solid rgba(230,230,230,1);
    border-top: 0px;
    overflow: hidden;
}
.lineCourseList>li .detail p{
    box-sizing: border-box;
    padding: 0 10px;
    color: #999999;
}
.lineCourseList>li .detail p:nth-of-type(1){
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #1A1A1A;
    font-size: 16px;
    line-height: 16px;
}
.lineCourseList>li .detail p:nth-of-type(2){
    font-size: 12px;
    line-height: 12px;
    margin-top: 6px;
}
.lineCourseList>li .detail p:nth-of-type(3){
    line-height: 18px;
    font-size: 12px;
    margin-top: 15px;
}
.lineCourseList>li .detail p:nth-of-type(3) img{
    display: inline;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 4px;
}
.lineCourseList>li .detail p:nth-of-type(3) span{
    float: right;
}
.free{
    color: #28BBAC;
}
.charge{
    color: #FF8B33;
}
.advertisingCourseList{
  display: flex;
  justify-content: space-between;
}
.advertisingCourseList>img{
  width: 256px;
  height: 438px;
}
.advertisingCourseList>ul{
  flex: 1;
  display: flex;
  justify-self: start;
  flex-wrap: wrap;
}
.advertisingCourseList>ul>li{
  width: 276px;
  height: 214px;
  background:rgba(255,255,255,1);
  border:1px solid rgba(230,230,230,1);
  margin-left: 10px;
}
.advertisingCourseList>ul>li:not(:nth-of-type(-n+3)){
  margin-top: 10px;
}
.advertisingCourseList>ul>li>.banner{
  width: 100%;
  height: 154px;
  position: relative;
  overflow: hidden;
}
.advertisingCourseList>ul>li>.banner img{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;top: 50%;
  transform: translate(-50%,-50%);
  transition: 0.5s;
}
.advertisingCourseList>ul>li:hover>.banner img{
  transform: translate(-50%,-50%) scale(1.1,1.1);
}
.advertisingCourseList>ul>li p{
  padding: 0 5px;
  box-sizing: border-box;
}
.advertisingCourseList>ul>li p:nth-of-type(1){
  font-size: 14px;
  color: #1A1A1A;
  line-height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 10px;
}
.advertisingCourseList>ul>li p:nth-of-type(2){
  font-size: 12px;
  color: #999999;
  margin-top: 6px;
}
.advertisingCourseList>ul>li p:nth-of-type(2) img{
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin: -2px 5px 0 0;
}
.advertisingCourseList>ul>li p:nth-of-type(2) span{
  float: right;
}
input:-webkit-autofill , textarea:-webkit-autofill, select:-webkit-autofill {
	  -webkit-box-shadow: 0 0 0px 1000px transparent  inset !important;
    background-color:transparent;
    background-image: none;
    transition: background-color 50000s ease-in-out 0s; 
}
input {
	 background-color:transparent;
}
.playback{
  background: none !important;
  color: #28BBAC !important;
}
.achieve{
    background-color: #FF8B33 !important;
    border-color: #FF8B33 !important;
}
.achieve:hover{
    background-color: rgba(255,139,51,0.8) !important;
    border-color: rgba(255,139,51,0.8) !important;
}
.myTrainingDetailMain .ant-menu-inline .ant-menu-item-selected::after,.myTrainingDetailMain .ant-menu-inline .ant-menu-item::after,
.CourseOverView .ant-menu-inline .ant-menu-item-selected::after,.CourseOverView  .ant-menu-inline .ant-menu-item::after
{
  left: 0 !important;
  right: auto;
}
.myTrainingDetailMain .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,.myTrainingDetailMain  .ant-menu-item:active,
.CourseOverViewBody .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,.CourseOverViewBody .ant-menu-item:active{
  background:none;
  background-image: linear-gradient(to right, #a3e2da , #ffffff);
  color: #1A1A1A;
}
.myTrainingDetailMain  .ant-menu-item
.CourseOverViewBody .ant-menu-item{
  padding-left: 0 !important;
  font-size: 14px;
  width: 100% !important;
}
.red{
  color: #E92D08;
}
.yellow{
  color: #FF8B33;
}
.green{
  color: #28BBAC;
}
.ant-table-cell{
  text-align: center !important;
}
tr .ant-table-cell:first-of-type{
  text-align: left !important;
}
.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder td{
  text-align: center !important;
  line-height: 300px !important;
  font-size: 15px;
  border: none;
}
.loading{
  position: absolute;
  left: 50%;top: 50%;
  transform: translate(-50%,-50%);
}