.header{
    height: 88px;
    background:rgba(255,255,255,1);
    box-shadow:0px 2px 4px 0px rgba(0, 0, 0, 0.16);
}
.header .content{
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.header .logo{
    width: 192px;
    height: 40px;
}
.header .navigationBar{
    width: 360px;
    margin-left: 54px;
    height: 50%;
    display: flex;
    /* justify-content: space-around; */
    justify-content: start;
    align-items: center;
    border-bottom: 0px;
}
.header .navigationBar li{
    height: 100%;
    padding: 0 20px;
    font-size: 16px;
    color: #1A1A1A;
    font-weight: 500;
    cursor: pointer;
    line-height: 44px;
}
.header .search{
    width: 256px;
    height: 34px;
    margin-left: 190px;
    position: relative;
}
.header .search input{
    width:256px;
    height:34px;
    border:1px solid rgba(204,204,204,1);
    border-radius:17px;
    padding: 0 45px 0 17px;
    box-sizing: border-box;
    color: #1A1A1A;
}
.header .search img{
    width: 28px;
    height: 28px;
    position: absolute;
    top: 50%;right: 14px;
    transform: translateY(-50%);
}
.header .user{
    width: 120px;
    margin-left: 20px;
    padding: 6px 0;
    height: 46px;
    line-height: 34px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #1A1A1A;
    cursor: pointer;
    position: relative;
}
.header .user .portrait{
    width: 34px;
    height: 34px;
    border-radius: 34px;
}
.header .user p{
    max-width: 86px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}
.header .user a{
    color: #1A1A1A;
}
.header .user a:hover{
    color: #28BBAC;
}
.header .userChange{
    width: 8px;
    height: 8px;
}
.header .ant-menu-horizontal > .ant-menu-item-selected,.header .ant-menu-horizontal > .ant-menu-item:hover{
    color: #28BBAC !important;
    border-bottom: 0px;
}
.ant-menu-item:active{
    background: none;
}
.ant-menu-horizontal > .ant-menu-item{
    border-bottom: 0px;
}

.header .user:hover .userPopup{
    display: flex;
}
.header .user .userPopup{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(46px);
    display: flex;
    flex-direction: column;
    background:rgba(255,255,255,1);
    border: 1px solid #E6E6E6;
    display: none;
}
.header .user .userPopup a{
    color: rgba(26,26,26,1);
    width: 100%;
    height: 40px;
    font-weight:400;
    text-indent: 23px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
}
.header .user .userPopup a:not(:last-child){
    border-bottom: 1px solid #E6E6E6;
}
.header .user .userPopup a:hover{
    color: #28BBAC;
    background-color: #ebebeb;
}