.playerMain>.ant-layout>.ant-layout-header{
    background-color: #27BBAB;
    height: 60px;
    position: relative;
}
.playerMain .ant-layout-header{
    padding: 0 50px 0 80px;
}
.playerMain .ant-layout-header label{
    font-size: 16px;
}
.playerMain .ant-layout-header label img{
    vertical-align: middle;
    margin-top: -2px;
}
.playerMain .ant-layout-header h1,.ant-layout-header label{
    line-height: 60px;
    height: 60px;
    color: #ffffff;
}
.playerMain .ant-layout-header h1{
    position: absolute;
    left: 50%;top: 0;
    transform: translateX(-50%);
}
.playerMain .videoContent{
    height: calc( 100vh - 60px );
}
.playerMain .ant-layout-content .ant-layout-header{
    height:40px;
    line-height: 40px;
    background:rgba(237,242,241,1);
    display: flex;
    justify-content: space-between;
}
.playerMain .ant-layout-content .ant-layout-header p:nth-of-type(1){
    color: #191919;
}
.playerMain .ant-layout-content .ant-layout-header p:nth-of-type(2){
    color: #7F7F7F;
}
.playerMain .ant-layout-content .ant-layout-header p:nth-of-type(2) img{
    vertical-align: middle;
    margin-top: -2px;
}
.playerMain .videoBox{
    height: calc(100vh - 100px);
    background-color: black;
}
.playerMain .rightSelect{
    width: 380px !important;
    max-width: 380px !important;
    min-width: 380px !important;
    background-color: #EFEFEF;
}
.playerMain .myProgress{
    background-color: #ffffff;
    height: 120px;
    padding: 30px;
    box-sizing: border-box;
}
.playerMain .myProgress p{
    font-size: 16px;
    color: #191919;
    width: 100%;
}
.playerMain .myProgress p span{
    font-size: 20px;
    color: #FF8B33;
    float: right;
}
.playerMain .ant-progress-bg{
    background-color:#FF8B33;
}
.playerMain .ant-progress-inner{
    height: 8px;
}
.playerMain .catalogueTitle{
    line-height: 48px;
    padding-left: 37px;
    color: #191919;
    font-size: 16px;
}
.playerMain .chapter {
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 14px;
    color: #191919;
}
.playerMain .chapter label{
    width: 76px;
    border-right: 1px solid #DBDBDB;
    position: relative;
    text-align: center;
    margin-right: 16px;
}
.playerMain .chapter label span{
    position: absolute;
    width: 13px;
    height: 13px;
    right: 0;top: 50%;
    border: 1px solid #DBDBDB;
    border-radius: 50%;
    transform: translate(50%,-50%);
    background-color: #ffffff;
}
.playerMain .ant-menu-inline .ant-menu-submenu-title,.ant-menu-sub.ant-menu-inline > .ant-menu-item{
    padding-left: 0 !important;
    margin: 0;
    height: 60px !important;
    line-height: 60px !important;
}
.playerMain .ant-menu-sub.ant-menu-inline > .ant-menu-item{
    color: #7F7F7F;
}
.playerMain .ant-menu-sub .chapter{
    color: #7F7F7F;
    font-weight: 400;
}
.playerMain .ant-menu-submenu-title:hover,.ant-menu-item:hover{
    background-color: #EAEAEA;
}
.playerMain .chapter div:first-of-type{
    width: 230px;
}
.playerMain .chapter div p{
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.playerMain .chapter div p:nth-of-type(2){
    margin-top: 2px;
}
.playerMain .chapter div p span{
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-top: 5px;
}
.playerMain .ant-menu-item-selected .chapter{
    color: #27BBAB !important;
}
.playerMain .ant-menu-inline .ant-menu-item::after{
    border: 0px;
}
.playerMain .thisProgress{
    display: none;
}
.playerMain .thisProgress div{
    width: 30px !important;
    height: 30px !important;
}
.playerMain .thisProgress .ant-progress-text{
    font-size: 10px !important;
    line-height: 20px;
    color: #27BBAB;
}
.playerMain .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path{
    stroke:#27BBAB !important
}
.playerMain .ant-menu-item-selected .thisProgress{
    display: block;
}
.menuBox{
    height: calc( 100vh - 228px);
    overflow: auto;
}
.ant-menu-item .anticon{
    margin-right: 0;
    font-size: 15px !important;
}
.ant-menu-item-selected .iconfont {
    color: #27BBAB;
}