.mainHeader{
    width: 100%;
    height: 88px;
    position: fixed;
    top: 0;
    z-index: 10;
}
.mainContent{
    padding: 120px 0 40px;
    overflow: hidden;
    min-height: calc(100vh - 260px);
    background-color: #f2f2f2;
}